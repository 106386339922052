import * as React from 'react';
import { Link } from 'gatsby';
import { Container } from 'react-bootstrap';
import Layout from '../components/layout';
import Seo from '../components/seo';

const Disclaimer = () => {
  return (
    <Layout>
      <Container className="my-3">
        <Seo title="リンクについて" />
        <h1 className="h2">リンクについて</h1>
        <div className="my-5">
          <div className="my-2">当ブログは基本的にリンクフリーです。リンクを行う場合の許可や連絡は不要です。</div>
          <div className="my-2">ただし、インラインフレームの使用や画像の直リンクはご遠慮ください。</div>
        </div>
        <div className="mt-5 text-center">
          <Link to="/" className="text-secondary text-decoration-none">記事一覧に戻る</Link>
        </div>
      </Container>
    </Layout>
  )
}

export default Disclaimer;